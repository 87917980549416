import { Observable } from 'rxjs';

import { FirestoreMeta } from '@app/core/firebase/firestore.service';
import { ID } from '@app/core/models/id';
import { VirtualVisit } from '@app/core/models/virtual-visit';

export enum VirtualVisitState {
  Queued = 'queued',
  Claimed = 'claimed',
  InProgress = 'in_progress',
  Ended = 'ended',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum VirtualVisitEndedBy {
  InternalUser = 'internal_user',
  Patient = 'patient',
}

export class VirtualVisitStates {
  static readonly QueuedAndInProgressStates = [
    VirtualVisitState.Queued,
    VirtualVisitState.Claimed,
    VirtualVisitState.InProgress,
  ];

  static readonly InProgressStates = [VirtualVisitState.Claimed, VirtualVisitState.InProgress];

  static readonly All = Object.values(VirtualVisitState);
}

export enum VideoCallType {
  Zoom = 'Zoom',
  OpenTok = 'OpenTok',
  AmazonClinic = 'AmazonClinic',
}

export interface UnhydratedVirtualVisit {
  id: string;
  visitState: VirtualVisitState;
  callbackRequested?: boolean;
  queuedBy: ID;
  queuedAt: Date;
  sessionId: string;
  videoCallType: VideoCallType;
  licensingBody?: string;
  claimedAt?: Date;
  claimedBy?: ID;
  reasonForVisit?: string;
  startedAt?: Date;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: Date;
  meta?: FirestoreMeta;
}

export const TIME_FIELDS = ['queuedAt', 'claimedAt', 'startedAt', 'endedAt', 'callbackRequestedAt'];

export interface VirtualVisitForUpdate<T> {
  id: string;
  visitState?: VirtualVisitState;
  claimedAt?: T;
  claimedBy?: ID | T;
  queuedBy?: ID;
  queuedAt?: T;
  licensingBody?: string;
  startedAt?: T;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: T;
  zoomMeetingId?: string | T;
  openTokSessionId?: string;
  meta?: FirestoreMeta;
  callbackRequested?: boolean;
}

export interface VirtualVisitForCreate<T> {
  visitState: VirtualVisitState;
  queuedBy: ID;
  queuedAt: T;
  licensingBody: string;
  openTokSessionId: string;
  claimedAt?: T;
  claimedBy?: ID;
  startedAt?: T;
  endedBy?: VirtualVisitEndedBy;
  endedAt?: T;
  meta?: FirestoreMeta;
}

export enum EndCallReason {
  PatientMissedCall = 'PatientMissedCall',
}

export interface VirtualVisitActions<T> {
  get(states: VirtualVisitState[]): Observable<UnhydratedVirtualVisit[]>;
  getCall(id: ID): Observable<UnhydratedVirtualVisit | undefined>;
  claimCall(visit: VirtualVisit, claimedBy: ID): Observable<VirtualVisit>;
  unclaimCall(id: ID): Observable<boolean>;
  startCall(id: ID): Observable<boolean>;
  unstartCall(id: ID): Observable<boolean>;
  endCall(id: ID, reason?: EndCallReason): Observable<boolean>;
  updateMetadata(visitId: ID, meta: FirestoreMeta): Observable<boolean>;
}

export interface VirtualVisitEvents {
  callEnded$(visitId: ID): Observable<void>;
  callFailed$(visitId: ID): Observable<void>;
  callTerminatedUnexpectedly$(visitId: ID): Observable<void>;
  callChangedFromClaimed$(visitId: ID): Observable<void>;
}
